import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import GUI from 'lil-gui'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js'
import { TextureLoader } from 'three'
import { Timer } from 'three/examples/jsm/Addons.js'
import { Howl, Howler } from 'howler'


/**
 * Base
 */
// Debug
// const gui = new GUI()


function isMobile() {
    var check = false;
    (function(a){
      if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0,4))) 
        check = true;
    })(navigator.userAgent||navigator.vendor||window.opera);
    return check;
  };

// Canvas
const canvas = document.querySelector('canvas.webgl')

// SOUND

let currentIntersect = null
let currentButton
let power = true
let speedGame = '800'
let speedPres = '800'
let soundToPlay
let skillLevel = 1

let isPlaying = false

var sndBtnA = new Howl
({
    src: ['./sound/sndBtnA.mp3'],
    sprite: {
        '800': [0, 800],
        '700': [0, 700],
        '600': [0, 600],
        '500': [0, 500],
        '400': [0, 400],
        '300': [0, 300],
        '200': [0, 200],
    },
    onplay: function()
    {
        isPlaying = true
        matBtnA.map = textureSimonColorOn
    },
    onend: function()
    {
        isPlaying = false
        matBtnA.map = textureSimonColor
    },
    rate: 1
    
})
var sndBtnB = new Howl
({
    src: ['./sound/sndBtnB.mp3'],
    sprite: {
        '800': [0, 800],
        '700': [0, 700],
        '600': [0, 600],
        '500': [0, 500],
        '400': [0, 400],
        '300': [0, 300],
        '200': [0, 200],
    },
    onplay: function()
    {
        isPlaying = true
        matBtnB.map = textureSimonColorOn
    },
    onend: function()
    {
        isPlaying = false
        matBtnB.map = textureSimonColor
    },
    rate: 1
    
})
var sndBtnC = new Howl
({
    src: ['./sound/sndBtnC.mp3'],
    sprite: {
        '800': [0, 800],
        '700': [0, 700],
        '600': [0, 600],
        '500': [0, 500],
        '400': [0, 400],
        '300': [0, 300],
        '200': [0, 200],
    },
    onplay: function()
    {
        isPlaying = true
        matBtnC.map = textureSimonColorOn
    },
    onend: function()
    {
        isPlaying = false
        matBtnC.map = textureSimonColor
    },
    rate: 1
    
})
var sndBtnD = new Howl
({
    src: ['./sound/sndBtnD.mp3'],
    sprite: {
        '800': [0, 800],
        '700': [0, 700],
        '600': [0, 600],
        '500': [0, 500],
        '400': [0, 400],
        '300': [0, 300],
        '200': [0, 200],
    },
    onplay: function()
    {
        isPlaying = true
        matBtnD.map = textureSimonColorOn
    },
    onend: function()
    {
        isPlaying = false
        matBtnD.map = textureSimonColor
    },
    rate: 1
    
})
var sndBtnE = new Howl
({
    src: ['./sound/sndError.mp3'],
    sprite: {
        '800': [0, 800],
        '700': [0, 700],
        '600': [0, 600],
        '500': [0, 500],
        '400': [0, 400],
        '300': [0, 300],
        '200': [0, 200],
    },
    onplay: function()
    {
        isPlaying = true
        matBtnA.map = textureSimonColorOn
        matBtnB.map = textureSimonColorOn
        matBtnC.map = textureSimonColorOn
        matBtnD.map = textureSimonColorOn
    },
    onend: function()
    {
        isPlaying = false
        matBtnA.map = textureSimonColor
        matBtnB.map = textureSimonColor
        matBtnC.map = textureSimonColor
        matBtnD.map = textureSimonColor
    },
    rate: 1
    
})

const btnA = (speed) =>
{   
    if(!isPlaying)
    {
        sndBtnA.play(speed)
    }
}
const btnB = (speed) =>
{   
    if(!isPlaying)
    {
        sndBtnB.play(speed)
    }
}
const btnC = (speed) =>
{   
    if(!isPlaying)
    {
        sndBtnC.play(speed)
    }
}
const btnD = (speed) =>
{   
    if(!isPlaying)
    {
        sndBtnD.play(speed)
    }
}
const btnE = (speed) =>
{   
    if(!isPlaying)
    {
        sndBtnE.play(speed)
    }
}

// Scene
const scene = new THREE.Scene()

// Groups
const simon = new THREE.Group()
const buttons = new THREE.Group()
const simonBase = new THREE.Group()
const simonBtnLevel = new THREE.Group()
const simonBtnLast = new THREE.Group()
const simonBtnPower = new THREE.Group()
const simonBtnStart = new THREE.Group()
const simonBtnGame = new THREE.Group()
const simonBtnLongest = new THREE.Group()
const simonBtnA = new THREE.Group()
const simonBtnB = new THREE.Group()
const simonBtnC = new THREE.Group()
const simonBtnD = new THREE.Group()



// OBjects
const textureLoader = new TextureLoader()
const fbxLoader = new FBXLoader()
const raycaster = new THREE.Raycaster()


// Material
const imgMatcap = textureLoader.load('./matcaps/8.png')
const textureSimonColor = textureLoader.load('./models/simon/textures/BAKE_DIFF.png')
const textureSimonColorOn = textureLoader.load('./models/simon/textures/BAKE_DIFF_ON.png')
const textureSimonMetal = textureLoader.load('./models/simon/textures/BAKE_METAL.png')
const textureSimonRoughness = textureLoader.load('./models/simon/textures/BAKE_ROUGH.png')
const textureSimonNormal = textureLoader.load('./models/simon/textures/BAKE_NORMAL.png') 
const textureSimonAO = textureLoader.load('./models/simon/textures/BAKE_AO.png')
textureSimonColor.colorSpace = THREE.SRGBColorSpace
textureSimonColorOn.colorSpace = THREE.SRGBColorSpace

const matcap = new THREE.MeshMatcapMaterial({
    matcap: imgMatcap
})

const matSimon = new THREE.MeshStandardMaterial({
    // wireframe: true,
    map: textureSimonColor,
    metalnessMap: textureSimonMetal,
    roughnessMap: textureSimonRoughness,
    // normalMap: textureSimonNormal,
    // aoMap: textureSimonAO
})

const matBtnA = new THREE.MeshStandardMaterial({
    map: textureSimonColor,
    metalnessMap: textureSimonMetal,
    roughnessMap: textureSimonRoughness
})
const matBtnB = new THREE.MeshStandardMaterial({
    map: textureSimonColor,
    metalnessMap: textureSimonMetal,
    roughnessMap: textureSimonRoughness
})
const matBtnC = new THREE.MeshStandardMaterial({
    map: textureSimonColor,
    metalnessMap: textureSimonMetal,
    roughnessMap: textureSimonRoughness
})
const matBtnD = new THREE.MeshStandardMaterial({
    map: textureSimonColor,
    metalnessMap: textureSimonMetal,
    roughnessMap: textureSimonRoughness
})



fbxLoader.load('./models/simon/simon2.fbx', (fbx) => 
    {
        fbx.children[5].material = matBtnA
        simonBtnA.add(fbx.children[5])
        fbx.children[8].material = matBtnB
        simonBtnB.add(fbx.children[8])
        fbx.children[6].material = matBtnC
        simonBtnC.add(fbx.children[6])
        fbx.children[5].material = matBtnD
        simonBtnD.add(fbx.children[5])

        for(let i = 0; i <= 6; i++)
            {
            fbx.children[i].material = matSimon
            }
        simonBtnLevel.add(fbx.children[0])
        simonBtnLast.add(fbx.children[0])
        simonBase.add(fbx.children[0])
        simonBtnStart.add(fbx.children[0])
        simonBtnPower.add(fbx.children[0])
        simonBtnLongest.add(fbx.children[0])
        simonBtnGame.add(fbx.children[0])
    })
    simonBtnLevel.position.x = -0.115
    simonBtnPower.position.x = 0.02

simon.add(simonBase, simonBtnA, simonBtnB, simonBtnC, 
    simonBtnD, simonBtnGame, simonBtnLast, simonBtnLevel, 
    simonBtnLongest, simonBtnPower, simonBtnStart)

buttons.add(simonBtnA, simonBtnB, simonBtnC, simonBtnD, 
simonBtnPower, simonBtnStart, simonBtnLast, simonBtnLevel, simonBtnLongest,)

scene.add(simon, buttons)
/**
 * Lights
 */
const ambientLight = new THREE.AmbientLight(0xffffff, 2.4)
scene.add(ambientLight)

const directionalLight = new THREE.DirectionalLight(0xffffff, 1.8)
// directionalLight.castShadow = true
// directionalLight.shadow.mapSize.set(1024, 1024)
// directionalLight.shadow.camera.far = 15
// directionalLight.shadow.camera.left = - 7
// directionalLight.shadow.camera.top = 7
// directionalLight.shadow.camera.right = 7
// directionalLight.shadow.camera.bottom = - 7
directionalLight.position.set(5, 5, -5)
scene.add(directionalLight)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})
// MOUSE

const mouse = new THREE.Vector2()
window.addEventListener('mousemove', (event) => {
    if(isMobile() === false)
    {
        mouse.x = event.clientX / sizes.width * 2 - 1
        mouse.y = - (event.clientY / sizes.height * 2 -1)
    }
})

const touch = new THREE.Vector2()
window.addEventListener('touchstart', (event) =>
{   

    if(isMobile() === true)
    {
        mouse.x = event.changedTouches[0].clientX / sizes.width * 2 - 1
        mouse.y = - (event.changedTouches[0].clientY / sizes.height * 2 -1)
    }
    
})



/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.set(0, 4, 0)
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.target.set(0, 0, 0)
controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

// FUNCTIONS

const onOff = () =>
{
    if(!power)
    {
        simonBtnPower.position.x = 0.02
        blink()
        power = true
    } else
    {
        simonBtnPower.position.x = -0.10
        matSimon.map = textureSimonColor
        power = false
    }
}


const blink = () =>
    {
        setTimeout(btnE, 0, '400')
        setTimeout(btnE, 500, '400')
    }
const error = () =>
    {
        setTimeout(btnE, 0, '600')
        setTimeout(btnE, 800, '600')
        setTimeout(btnE, 1600, '600')
    }



// LEVEL CHANGE

const levelChange = () =>
{
    switch(skillLevel)
    {
        case 1:
            skillLevel = 2
            speedGame = '600'
            speedPres = '600'
            simonBtnLevel.position.x = -0.06
            break
        case 2:
            skillLevel = 3
            speedGame = '400'
            speedPres = '400'
            simonBtnLevel.position.x = -0.005
            break
        case 3:
            skillLevel = 4
            speedGame = '200'
            speedPres = '200'
            simonBtnLevel.position.x = 0.05
            break
        case 4:
            skillLevel = 1
            speedGame = '800'
            speedPres = '800'
            simonBtnLevel.position.x = -0.115
            break
    }
}

// GAME

let memoryGame = []
let memoryTurn = []
let memoryShow = []
let isGameActive = false

const game1start = () =>
{   
    memoryGame = []
    isGameActive = true

    if(memoryGame.length == 0)
    {
        for(let i = 0; i <= 2; i++)
            {
                let number = Math.random()
        
                if(number > 0 && number <= 0.25){memoryGame.push('A')}
                if(number > 0.25 && number <= 0.5){memoryGame.push('B')}
                if(number > 0.5 && number <= 0.75){memoryGame.push('C')}
                if(number > 0.75 && number <= 1){memoryGame.push('D')}
            }
    }

        memoryTurnPush()
        memoryShowPush()
        setTimeout(showMemory, 500)
    
}



const memoryTurnPush = () =>
{   
    memoryTurn = []
    for(let i = 0; i < memoryGame.length; i++)
    {
        memoryTurn.push(memoryGame[i])
    }
}

const memoryShowPush = () =>
{   
    memoryShow = []
    for(let i = 0; i < memoryGame.length; i++)
    {
        memoryShow.push(memoryGame[i])
    }
}

const addStep = () =>
    {
        if(isGameActive && memoryTurn.length == 0)
        {
            let number = Math.random()
            
            if(number > 0 && number <= 0.25){memoryGame.push('A')}
            if(number > 0.25 && number <= 0.5){memoryGame.push('B')}
            if(number > 0.5 && number <= 0.75){memoryGame.push('C')}
            if(number > 0.75 && number <= 1){memoryGame.push('D')}
    
            memoryTurnPush()
            memoryShowPush()
            setTimeout(showMemory, 1000)
            
        }
    }

const showMemory = () =>
    {
        if(memoryShow.length > 0)
        {   
            switch(memoryShow[0])
            {
                case 'A':
                btnA(speedGame)
                break
                case 'B':
                btnB(speedGame)
                break
                case 'C':
                btnC(speedGame)
                break
                case 'D':
                btnD(speedGame)
                break
            }

            memoryShow.shift()

            setTimeout(showMemory, Number(speedGame) + 200)
        } else
        {
            memoryShowPush()
        }
    }

const buttonCheck = (button) =>
{   

    if(isGameActive && memoryTurn.length > 0)
    {
        if(button == memoryTurn[0])
        {   
            memoryTurn.shift()
            addStep()
        } else
        {
            error()
            isGameActive = false
        }
        
    } 
    
}

const random = (size) =>
{
    memoryGame = []
    for(let i = 0; i <= size; i++)
    {
        let number = Math.random()

        if(number > 0 && number <= 0.25){memoryGame.push('A')}
        if(number > 0.25 && number <= 0.5){memoryGame.push('B')}
        if(number > 0.5 && number <= 0.75){memoryGame.push('C')}
        if(number > 0.75 && number <= 1){memoryGame.push('D')}
    }

    showMemory()

}




// CONTROLS
const funcButtons = () =>
{
    if(!power)
        {
            switch (currentButton)
            {
                case 'BTNPOWER':
                    onOff()
                    break
            } 
                
        } else
        {
                switch(currentButton)
                {
                    case 'BTNPOWER':
                        onOff()
                        break

                    case 'BTNA':
                        btnA(speedPres)
                        buttonCheck('A')
                        break

                    case 'BTNB':
                        btnB(speedPres)
                        buttonCheck('B')
                        break

                    case 'BTNC':
                        btnC(speedPres)
                        buttonCheck('C')
                        break

                    case 'BTND':
                        btnD(speedPres)
                        buttonCheck('D')
                        break
                    case 'BTNSTART':
                        game1start()
                        break
                    case 'BTNLAST':
                        showMemory()
                        break
                    case 'BTNLONGEST':
                        random(10)
                        break
                    case 'BTNLEVEL':
                        levelChange()
                        break
                }
        }
}

window.onload = function()
{
    if(isMobile() === false)
    {
        window.onmousedown = function(event)
        {   
            funcButtons()
        }
    } else
    {  
        window.ontouchend = function(event)
        {   
            funcButtons()
        }
    }
}

/**
 * Animate
 */

const clock = new THREE.Clock()
let previousTime = 0

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime

    //RAYCASTER

    raycaster.setFromCamera(mouse, camera)
  
    const objectsToTest = [buttons]
    const intersects = raycaster.intersectObjects(objectsToTest)

    if(intersects.length)
    {   
       currentButton = intersects[0].object.name
       if(!currentIntersect)
        {
            // console.log(intersects[0].object.name)
        }
            currentIntersect = intersects[0]

    } else {
        if(currentIntersect){
            currentButton = ""
            // simonBtnPower.position.x = -0.10
            matSimon.map = textureSimonColor
        }

        currentIntersect = null
        }

    for(const object of objectsToTest) {
        if(!intersects.find(intersect => intersect.object === object)) {
        }
    }


    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()